* a {
  color: #e31e25;
}

a:hover {
  color: #e31e25;
}

.mb {
  margin-bottom: 0 !important;
}

h5,
.h5 {
  color: #e31e25;
  text-transform: none;
}

.icRight {
  position: relative;
  margin-top: 15px;
}

.tablecom {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.txtpagebreak {
  page-break-before: always;
}

.page-main-header .main-header-right svg line {
  color: #2f3c4e;
}

.page-main-header .main-header-right .nav-right ul li svg {
  color: #e31e25;
}

.page-main-header .main-header-right .nav-right ul li .dot {
  background-color: #e31e25;
}

.page-main-header .main-header-right .nav-right ul li .media .dotted-animation .animate-circle {
  border: 5px solid #e31e25;
}

.page-main-header .main-header-right .nav-right ul li .media .dotted-animation .main-circle {
  background-color: #e31e25;
}

.card .card-header h5 {
  text-transform: none;
  font-weight: 400;
}

.form-group {
  margin-bottom: 25px;
}

.form-control {
  border-color: #dee2e6 !important;
}

.pagination-primary .page-item .page-link {
  color: #2f3c4e;
}

.pagination-primary .page-item.active .page-link {
  color: #fff;
  background-color: #e31e25;
  border-color: #e31e25;
}

.checkbox_animated:before {
  border-color: #e31e25;
}

.radio_animated:before {
  background: #e31e25;
}

.radio_animated:after {
  border: 2px solid #ced4da;
}

.topmargin30 {
  margin-top: 31px !important;
}

.authentication-box .btn-primary {
  background-color: #2f3c4e !important;
  border-color: #2f3c4e !important;
}

.swal2-styled.swal2-confirm {
  background-color: #e31e25 !important;
}

.swal2-styled.swal2-cancel {
  background-color: #2f3c4e !important;
}

.page-main-header .main-header-right .nav-right .profile-dropdown {
  width: 168px;
  top: 50px;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:nth-child(2) {
  border-top: 1px solid #eff0f1;
  padding-top: 20px;
}

.page-main-header .main-header-right .nav-right .profile-dropdown h6 {
  color: #313131;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.5px;
  margin-bottom: 3px;
}

.page-main-header .main-header-right .nav-right .profile-dropdown p {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 0;
  color: #e31e25;
  padding-bottom: 15px;
}

#stepRws .footer-buttons {
  text-align: center;
  margin-top: 15px;
}



.dzu-dropzone {
  border: 2px dashed #2f3c4e !important;
  background: rgba(0, 0, 0, 0.1);
}

.dzu-inputLabel {
  color: #2f3c4e !important;
}



.pb0 {
  padding-bottom: 0px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb0 {
  margin-bottom: 0px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt50 {
  margin-top: 50px;
}

.mt175 {
  margin-top: 175px;
}


.text-orng {
  color: #fa9029 !important;
}

.text-red {
  color: #ff0000 !important;
}

.text-required {
  color: #ff0000;
  font-style: italic;
  margin-top: 5px;
  font-size: 14px;
}



.page-link {
  color: #2f3c4e;
}

.page-item.active .page-link {
  background-color: #e31e25;
  border-color: #e31e25;
}

.react-bootstrap-table-pagination {
  margin-top: 20px;
}

.dropdown-item a {
  color: #2f3c4e !important;
}



.linkCurson {
  cursor: pointer;
}

.textUnderline {
  text-decoration: underline;
}


.pointsInline .list-inline-item:not(:last-child) {
  border-right: 1px solid #fff;
  padding-right: 8px;
  margin-bottom: 6px;
  margin-top: 6px;
}




.page-wrapper .page-body-wrapper .page-header .row h3 {
  text-transform: none;
}

/************10-05-2022***********/

/* input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
} */
/************21-02-2022***********/

.stepper-wrapper * {
  box-sizing: border-box;
}

.stepper-wrapper .stepper-head {
  display: flex;
  position: relative;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* styles of inline stepper */
  /* styles of vertical stepper */
}

.stepper-wrapper .stepper-head.inline-stepper-head .stepper-step:after {
  top: 50%;
  transform: translateY(-50%);
}

.stepper-wrapper .stepper-head.inline-stepper-head .stepper-indicator,
.stepper-wrapper .stepper-head.inline-stepper-head .stepper-label {
  display: inline-block;
  vertical-align: middle;
}

.stepper-wrapper .stepper-head.inline-stepper-head .stepper-label {
  text-align: left;
  padding: 0 0.5rem;
  background: #fff;
}

.stepper-wrapper .stepper-head.vertical-stepper-head {
  flex-direction: column;
  width: auto;
}

.stepper-wrapper .stepper-head.vertical-stepper-head .stepper-step {
  display: flex;
  align-items: center;
  text-align: start;
}

.stepper-wrapper .stepper-head.vertical-stepper-head .stepper-step:after {
  content: " ";
  position: absolute;
  left: 1.5rem;
  right: auto;
  top: 2rem;
  bottom: -0.5rem;
  width: 0;
  height: auto;
  border-left: 0.125rem solid #e3e8ec;
}

.stepper-wrapper .stepper-head.vertical-stepper-head .stepper-step.rightToLeft:after {
  right: 1.5rem;
  left: auto;
}

.stepper-wrapper .stepper-head.vertical-stepper-head .stepper-label {
  -webkit-padding-start: 1rem;
  padding-inline-start: 1rem;
}

.stepper-wrapper .stepper-head.vertical-stepper-head+.stepper-body {
  -webkit-margin-start: 3.5rem;
  margin-inline-start: 3.5rem;
}

.stepper-wrapper .stepper-head .stepper-step {
  position: relative;
  text-align: center;
  padding: 0.5rem;
  flex-basis: 100%;
}

.stepper-wrapper .stepper-head .stepper-step.rightToLeft:after {
  left: -50%;
}

.stepper-wrapper .stepper-head .stepper-step.is-complete .stepper-indicator-info {
  border-color: #4caf50;
  background-color: #4caf50;
  cursor: pointer;
}

.stepper-wrapper .stepper-head .stepper-step.is-complete .stepper-label {
  color: #4caf50;
}

.stepper-wrapper .stepper-head .stepper-step.is-active .stepper-indicator-info {
  border-color: #627c90;
  background-color: #627c90;
  cursor: initial;
}

.stepper-wrapper .stepper-head .stepper-step.is-active .stepper-label {
  color: #627c90;
}

.stepper-wrapper .stepper-head .stepper-step.is-warning .stepper-indicator-info {
  border-color: #f1c40f;
  background-color: #f1c40f;
}

.stepper-wrapper .stepper-head .stepper-step.is-warning .stepper-label {
  color: #f1c40f;
}

.stepper-wrapper .stepper-head .stepper-step.is-error .stepper-indicator-info {
  border-color: #e95a4b;
  background-color: #e95a4b;
}

.stepper-wrapper .stepper-head .stepper-step.is-error .stepper-label {
  color: #e95a4b;
}

.stepper-wrapper .stepper-head .stepper-step:after {
  content: " ";
  position: absolute;
  left: 50%;
  top: 1.5rem;
  width: 100%;
  height: 0.125rem;
  background-color: #e3e8ec;
  z-index: 1;
}

.stepper-wrapper .stepper-head .stepper-step:last-child:after {
  display: none;
}

.stepper-wrapper .stepper-head .stepper-indicator {
  position: relative;
  display: block;
  z-index: 2;
}

.stepper-wrapper .stepper-head .stepper-indicator-info {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid #e3e8ec;
  border-radius: 50%;
  background-color: #e3e8ec;
  font-size: 1rem;
  line-height: 2.5rem;
  text-align: center;
  color: #fff;
  z-index: 2;
}

.stepper-wrapper .stepper-head .stepper-indicator-info .stepper-tick {
  width: 14px;
  fill: #ffffff;
}

.stepper-wrapper .stepper-head .stepper-label {
  position: relative;
  display: block;
  margin: 0.5rem 0;
  color: #cfd7de;
  z-index: 2;
}

.stepper-wrapper .stepper-body {
  flex: 1;
  min-height: 100px;
  padding: 10px 0;
}

.stepper-wrapper .stepper-footer {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.stepper-wrapper .stepper-footer-btn {
  color: rgba(0, 0, 0, 0.87);
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  cursor: pointer;
  border: none;
  outline: none;
}

.stepper-wrapper .stepper-footer-btn.primary {
  color: #fff;
  background-color: #1976d2;
}

.stepper-wrapper .stepper-footer-btn.success {
  color: #fff;
  background-color: #4caf50;
}

.stepper-wrapper .stepper-footer-btn:disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: rgba(0, 0, 0, 0.12);
  cursor: not-allowed;
}




.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a {
  color: #000 !important;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg {
  color: #000 !important;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg path,
.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg line {
  color: #000 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #000 !important;
}

.pagination-primary .page-item.active .page-link {
  background-color: #e31e25;
  border-color: #e31e25;
}

h5,
.h5 {
  color: #e31e25;
}


.btn-primary {
  background-color: #e31e25 !important;
  border-color: #e31e25 !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
  background-color: #cb1118 !important;
  border-color: #cb1118 !important;
}


.signinLogoHeader {
  background-color: #e31e25;
  padding: 10px 0 0 0;
  text-align: center;
}

.logoTagLine {
  background-color: #fff;
  text-align: center;
  font-size: 10px;
  color: #000;
  padding: 1px;
}

.logoTagLine span {
  color: #e31e25;
  font-weight: bold;
}

.main-header-left {
  background-color: #e31e25 !important;
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.checkbox_animated:disabled,
.checkbox_animated[readonly] {
  background-color: #a19c9c;
  opacity: 1;
}

.checkbox_animated:disabled:before {
  border-color: #a19c9c;
}

.radio_animated:disabled:before {
  border-color: #a19c9c;
  background: #a19c9c;
}

@keyframes spinner-border {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* 02.11.2022 Work Start */
.switch-sm .switch {
  width: 25px;
  height: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.page-main-header .main-header-right .nav-right>ul {
  display: flex;
  align-items: center;
  padding-top: 15px;
}

.page-main-header .main-header-right .nav-right>ul .form-control:focus {
  color: #495057;
  background-color: #fff;
  outline: 0;
  border: 1px solid lightgray;
  box-shadow: none;
}

.m-checkbox-inline label {
  margin-right: 10px;
}

.isprocessinglabclass {
  padding: 30px 0 0 30px;
}

.page-wrapper .page-body-wrapper .page-header .breadcrumb a {
  padding-top: 5px;
  display: block;
}

.labObservationtablesec {
  display: block;
  width: 100%;
}

/* .labObservationtablesec .react-bootstrap-table table tbody tr td { width: 75%; max-width: 70px;}
.react-bootstrap-table table { display: block; overflow-x: auto; white-space: nowrap;} */
/* .react-bootstrap-table { display: block; width: 100%;}
.react-bootstrap-table .table {display: block;  overflow-x: auto;  white-space: nowrap;} */
.printReportContainerModel {
  max-width: 1400px;
  width: 100%;
}

.PrintDayEndReport .alert-dark {
  background-color: rgba(42, 49, 66, 0.8);
  border-color: rgba(42, 49, 66, 0.9) !important;
  color: #fff;
}

.summaryHeadingAlertSec .alert-success {
  background-color: rgba(34, 175, 71, 0.8) !important;
  border-color: rgba(34, 175, 71, 0.9) !important;
  color: #fff !important;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.react-bootstrap-table .table {
  /*display: block;*/
  overflow-x: auto;
   /* white-space: nowrap; */
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
}

.react-bootstrap-table .table td {
  width: inherit;
}

.searchButtonSec {
  display: flex;
  align-items: center;
  height: 100px;
}

@media only screen and (min-device-width:990px) and (max-device-width:1037px) {
  .page-main-header .main-header-right .nav-right {
    text-align: right;
    padding-left: 0px;
    display: block;
    float: right;
    width: 90%;
    padding-top: 15px !important;
  }

  .page-main-header .main-header-right .nav-right>ul {
    padding-top: 0px;
  }

  .page-main-header .main-header-right svg line {
    color: #2f3c4e;
  }
}

/* 02.11.2022 Work End */

/************** DatePicker css**************/

.dateIcon {
  position: relative;
}

.dateIcon .fa-calendar,
.dateIcon .fa-clock-o {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -6px;
}

.react-datepicker_time-container .react-datepickertime .react-datepicker_time-box {
  width: 90px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 90px !important;
}

#tbLedger td:nth-child(9),
#tbLedger td:nth-child(10),
#tbLedger th:nth-child(9),
#tbLedger th:nth-child(10) {
  background: #FFE0E6;
}

#tbLedger td:nth-child(11),
#tbLedger td:nth-child(12),
#tbLedger th:nth-child(11),
#tbLedger th:nth-child(12) {
  background: #FFE9B3;
}

#tbLedger td:nth-child(13),
#tbLedger td:nth-child(14),
#tbLedger th:nth-child(13),
#tbLedger th:nth-child(14) {
  background: #DDFFF7;
}

#tbLedger td:nth-child(15),
#tbLedger td:nth-child(16),
#tbLedger th:nth-child(15),
#tbLedger th:nth-child(6) {
  background: #F4EFE6;
}


/* start Added By Suresh*/
.tbInvoice {
  border: solid 1px #000;
  font-size: 16px;
  line-height: 1.4;
}

.tdHeaderInvoice {
  padding: 10px;
}

.tdHeaderInvoice h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 0px;
}

.taxInvoice {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 0px;
}

.brdrTop {
  border-top: solid 1px #000;
}

.brdrBottom {
  border-bottom: solid 1px #000;
}

.brdrLeft {
  border-left: solid 1px #000;
}

.brdrRight {
  border-right: solid 1px #000;
}

.p-10 {
  padding: 10px;
}

.txtUnderline {
  text-decoration: underline;
}

.tbValueDiscount tr td {
  padding: 5px 10px;
  font-weight: bold;
}

.tbItem tr td {
  padding: 5px 10px;
}

.bxShipping {
  border: solid 1px #ced4da;
  padding: 20px 15px;
}

.bxShipping.dactive {
  background-color: #f6f6f6;
}

@media print {

  table.tbInvoice,
  table.tbInvoice tr td,
  table.tbInvoice tr th {
    page-break-inside: avoid !important;
    page-break-before: avoid !important;
    page-break-after: avoid !important;
  }
}

/* Start Added by Suresh for EWB Invoice 26 Apr 2024*/

#bill-board {
  padding: 10px;
}

#bill-board .table-container {
  border: 1px solid #ddd;
  padding: 0px 4px;

}

#bill-board table {
  width: 100%;

  border-collapse: collapse;
}

#bill-board table,
#bill-board th,
#bill-board td {
  border: 1px solid #ddd;
  padding: 5px;
  text-align: left;
}

#bill-board .outer_table td,
#bill-board .outer_table th {
  padding-left: 10px;
}

#bill-board .center {
  display: block;
  margin: 3px auto;
}

#bill-board tfoot {
  margin: 3px 0px;
}

#bill-board .bold {
  font-weight: bold;
}

#bill-board .head {
  border: 1px solid #ddd;
  margin: 4px 0px;
}

#bill-board .inner-table {
  margin: -1px 0px 7px 0px;
  padding: 6px 5px;
}

/* End Added By Suresh  26 Apr 2024 */


/* End Added By Suresh*/


/******* Mobile******/
@media only screen and (min-device-width:320px) and (max-device-width:767px) {
  .page-main-header .main-header-right svg line {
    color: #fff;
  }

  /* .react-bootstrap-table { display: block;width: 100%;}
.react-bootstrap-table {overflow: scroll; width: 100%;} */
  .billingandfinance input.checkbox_animated {
    margin-bottom: 0;
    margin-top: 0 !important;
  }

  .isprocessinglabclass {
    padding: 0;
  }

  .localities__listsec .row.react-bootstrap-table-pagination div:first-child,
  .row.react-bootstrap-table-pagination div:last-child {
    width: 100%;
    margin-bottom: 30px;
    font-size: 14px;
  }

  .localities__listsec .row.react-bootstrap-table-pagination div:first-child,
  .row.react-bootstrap-table-pagination div:last-child {
    width: 95%;
    font-size: 14px;
    display: block;
    margin: 10px auto;
  }

  .localities__listsec .row.react-bootstrap-table-pagination div:first-child,
  .row.react-bootstrap-table-pagination div:last-child ul.pagination.react-bootstrap-table-page-btns-ul li.page-item a {
    padding: 7px 12px;
  }

  /* .react-bootstrap-table table { display: block; overflow-x: auto!important; table-layout: auto!important;  white-space: nowrap;} */

  .table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
    width: 100% !important;
  }


  .text-xs-center {
    text-align: center;
  }

  .salesreportsec label:nth-child(1) {
    display: block;
    width: 100%;
  }

  .salesreportsec label:nth-child(2) {
    margin-left: 0 !important;
    margin-bottom: 20px;
  }

  .center__type label {
    display: block;
    width: 100%;
  }

  .__center__buttonsec button {
    font-size: 14px;
    padding: 5px 5px;
  }

  span.react-bootstrap-table-pagination-total {
    display: none;
  }

  .nav-menus li:nth-child(1) .css-b62m3t-container {
    display: block;
    width: 100%;
    padding: 3px 5px;
  }

  .page-wrapper .page-main-header .main-header-right .nav-right>ul>li {
    padding: 0 1px;
  }

  .css-tlfecz-indicatorContainer {
    color: hsl(0, 0%, 80%);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 4px !important;
    -webkit-transition: color 150ms;
    transition: color 150ms;
    box-sizing: border-box;
  }

  .page-main-header .main-header-right .nav-right ul li svg {
    margin-top: 0px;
    width: 18px;
    height: 18px;
    color: #e31e25;
  }

  button.btn.btn-primary {
    font-size: 14px;
  }
}


@media only screen and (min-device-width:320px) and (max-device-width:480px) and (orientation:portrait) {
  .text-xs-center {
    text-align: center;
  }
}


@media only screen and (min-device-width:320px) and (max-device-width:767px) and (orientation:landscape) {}




/****** IPADs *********/
@media only screen and (min-device-width:768px) and (max-device-width:1024px) {}



@media only screen and (min-width:768px) and (max-width:990px) and (orientation:portrait) {

  /* .page-main-header .main-header-right svg line { color: #fff;} */
  .page-main-header .main-header-right svg line {
    color: #ffffff !important;
  }

  .page-main-header .main-header-right .nav-right>ul>li:first-child {
    width: 80%;
  }

  .page-wrapper .page-body-wrapper .page-sidebar.open {
    display: block;
    margin-left: calc(-260px);
  }



}


@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:landscape) {
  .logoTagLine {
    padding: 1px;
  }
}




/* (1920x1080) Full HD Display */
/*@media  screen and (max-width: 1920px)*/
@media screen and (min-width: 1920px) {}





@media only screen and (max-width: 991px) {
  .page-main-header .main-header-right {
    background-color: #e31e25;
  }

  .page-wrapper .page-main-header .main-header-left .logo-wrapper img {
    height: 45px;
    display: block;
    margin: 0 auto;
  }

  .main-header-left {
    width: 255px;
  }

  .page-main-header .main-header-right {
    padding-left: 0px;
  }

  .page-main-header .main-header-right svg line {
    color: #ffffff !important;
  }

  .page-wrapper .page-body-wrapper .page-sidebar.open {
    display: none;
    margin-left: calc(-260px);
  }

  .page-main-header .main-header-right .nav-right>ul {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 15px !important;
  }

  /* .react-bootstrap-table table { table-layout: inherit;}
        .react-bootstrap-table table {display: block;overflow-x: auto; white-space: nowrap;} */
  .table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
    /* display: table-caption; 
          width: 100%; */
  }

}




/* iPhone X in portrait & landscape */
@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
  /* STYLES GO HERE */
}


/* iPhone X in portrait */
@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : portrait) {}


/* iPhone X in landscape */
@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : landscape) {}





/*iPhone 6, 7, & 8 portrait & landscape*/
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
  /* STYLES GO HERE */
}


/*iPhone 6, 7, & 8 landscape*/
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : landscape) {
  /* STYLES GO HERE */
}


/*iPhone 6, 7, & 8 portrait & landscape*/
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : portrait) {
  /* STYLES GO HERE */
}



/*iPhone 6, 7, & 8 Plus in portrait & landscape*/
@media only screen and (min-device-width : 414px) and (max-device-width : 736px) {
  /* STYLES GO HERE */
}


/*iPhone 6, 7, & 8 Plus in  landscape*/
@media only screen and (min-device-width : 414px) and (max-device-width : 736px) and (orientation : landscape) {
  /* STYLES GO HERE */
}


/*iPhone 6, 7, & 8 Plus in portrait*/
@media only screen and (min-device-width : 414px) and (max-device-width : 736px) and (orientation : portrait) {
  /* STYLES GO HERE */
}



/*iPhone 5 & 5S in portrait & landscape*/
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
  /* STYLES GO HERE */
}


/*iPhone 5 & 5S in landscape*/
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : landscape) {
  /* STYLES GO HERE */
}


/*iPhone 5 & 5S in portrait*/
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : portrait) {
  /* STYLES GO HERE */
}




@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:landscape) and (-webkit-min-device-pixel-ratio:2) {}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait) and (-webkit-min-device-pixel-ratio:2) {}

@media only screen and (min-width:1224px) {}

@media only screen and (min-width:1824px) {}

@media only screen and (min-device-width:320px) and (max-device-width:480px) and (orientation:landscape) and (-webkit-min-device-pixel-ratio:2) {}

@media only screen and (min-device-width:320px) and (max-device-width:480px) and (orientation:portrait) and (-webkit-min-device-pixel-ratio:2) {}

@media only screen and (min-device-width:320px) and (max-device-height:568px) and (orientation:landscape) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:320px) and (max-device-height:568px) and (orientation:portrait) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:375px) and (max-device-height:667px) and (orientation:landscape) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:375px) and (max-device-height:667px) and (orientation:portrait) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:414px) and (max-device-height:736px) and (orientation:landscape) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:414px) and (max-device-height:736px) and (orientation:portrait) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:320px) and (max-device-height:640px) and (orientation:landscape) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:320px) and (max-device-height:640px) and (orientation:portrait) and (-webkit-device-pixel-ratio:2) {}

@media only screen and (min-device-width:320px) and (max-device-height:640px) and (orientation:landscape) and (-webkit-device-pixel-ratio:3) {}

@media only screen and (min-device-width:320px) and (max-device-height:640px) and (orientation:portrait) and (-webkit-device-pixel-ratio:3) {}

@media only screen and (min-device-width:360px) and (max-device-height:640px) and (orientation:landscape) and (-webkit-device-pixel-ratio:3) {}

@media only screen and (min-device-width:360px) and (max-device-height:640px) and (orientation:portrait) and (-webkit-device-pixel-ratio:3) {}

@media screen and (-webkit-min-device-pixel-ratio:0) {}



/***Kavish Raghav***/
.advancepaymentradio label {
  display: inline-block;
  margin-bottom: 0.5rem;
  padding: 5px 5px;
}

.advancepaymentradio label input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  margin-right: 5px;
  height: 12px;
  line-height: 22px;
}

.removeValue {
  color: transparent;
}

/***Kavish Raghav***/

#UTable .react-bootstrap-table .table {
  margin-bottom: 0px;
  table-layout: fixed !important;
  white-space: wrap !important;
}

#UTable .react-bootstrap-table tr td {
  background: #fff !important;
}

.alert-info {
  /* color: red; */
  background-color: #d1ecf1 !important;
  border-color: #bee5eb;
  /* font-size: 9px;  */
}

#cashTable .react-bootstrap-table .table {
  margin-bottom: 0px;
  table-layout: fixed !important;
  white-space: wrap !important;
}

#paytmTable .react-bootstrap-table .table {
  margin-bottom: 0px;
  table-layout: fixed !important;
  white-space: wrap !important;
}

#bharatPayTable .react-bootstrap-table .table {
  margin-bottom: 0px;
  table-layout: fixed !important;
  white-space: wrap !important;
}

#phonePeTable .react-bootstrap-table .table {
  margin-bottom: 0px;
  table-layout: fixed !important;
  white-space: wrap !important;
}

#creditCardTable .react-bootstrap-table .table {
  margin-bottom: 0px;
  table-layout: fixed !important;
  white-space: wrap !important;
}

#debitCardTable .react-bootstrap-table .table {
  margin-bottom: 0px;
  table-layout: fixed !important;
  white-space: wrap !important;
}

#gPayTable .react-bootstrap-table .table {
  margin-bottom: 0px;
  table-layout: fixed !important;
  white-space: wrap !important;
}

#onlineTable .react-bootstrap-table .table {
  margin-bottom: 0px;
  table-layout: fixed !important;
  white-space: wrap !important;
}

.thirdParty .dropdown-menu {
  font-size: 0.75rem !important;
}

.routeSN {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 600;
}
.fixed-width-select {
  width: 300px !important; /* or any other desired width */
}

.bill-container .footer p {
  font-size: 12px;
  color: #555;
}

.bill-container .footer p strong {
  font-size: 14px;
  color: #000;
}

.bill-container p {
  margin-bottom: 10px !important;
}